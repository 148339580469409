.spinWrapper {
  position: relative;
  height: 100px;
  width: 100px; 
  background: #fff;
  overflow: hidden;
}

.letter { 
  height: 100px; 
  font-size: 60px; 
  border: 1px solid black; 
  position: relative;
  text-align: center;
}

.letter.spin{
  animation: moveup 5s 1;
  animation-fill-mode: forwards;
}

@keyframes moveup {
  from {bottom: 0px;}
  to {bottom: 2500px;}
}
