@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');

/* app colors:
purple: #7b2cbf (background, cta buttons)
pink: #FF006E   (background, warnings, errors)

orange: #FB5607 (not sure yet)
yellow: #FFBE0B (not sure yet)
*/

#root{
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, #7b2cbf, #FF006E);
  font-family: 'Quicksand', sans-serif;
}

.chatBox {
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;
  display: flex;
  flex-grow: 1;
}

.chatBox::-webkit-scrollbar {
  background: transparent;
  width: 4px;
}
.chatBox::-webkit-scrollbar-thumb {
  background: #fff;
}

.chatBox > div > div:last-child {
  scroll-snap-align: end;
}

.bgCircleWrap {
  height: 100%; 
  width: 100%; 
  position: absolute; 
  z-index: 0; 
  top: 0; 
  display: flex; 
  justify-content: center; 
  min-height: 600px; 
  min-width: 600px;
  overflow: hidden;
}

.bgCircle.keepSpinning {
  animation: bigBgSpin 20s linear infinite;
}

.bgCircle.spinOnce {
  animation: bigBgSpin 5s 1;
}

@keyframes bigBgSpin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(1440deg); }
}


/* temporary fix - change these once you add a real theme */
.ant-btn-primary {
  background-color: #FF006E !important;
  border-color: #FF006E !important;
}
.ant-btn-link {
  color: #FF006E !important;
}

.ant-card-hoverable{
  transition: none !important;
  -webkit-transition: none !important;
}

.ant-card-hoverable:hover{
  outline: 1px solid #f0f0f0;
  box-shadow: 0px 3px 27px 6px #ffffff4f !important;
}

.ant-radio-button-wrapper{
  background: #ffffff4f !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #fff !important;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-bottom: none !important;
}

.logo-light:hover{
  opacity: 1
}